import Toastify from "toastify-js";

const successToast = (message: string) => {
  Toastify({
    text: message,
    duration: -1,
    newWindow: true,
    close: true,
    gravity: "top",
    position: "right",
    backgroundColor: "#0e2c88",
    stopOnFocus: true,
  }).showToast();
};

const errorToast = (message: string) => {
  Toastify({
    text: message,
    duration: -1,
    newWindow: true,
    close: true,
    gravity: "top",
    position: "right",
    backgroundColor: "#D32929",
    stopOnFocus: true,
  }).showToast();
};

/**
 * @param message value is string
 * @param durationHide in second number, default 5 seconds
 * @returns void, will automatically show the toast
 */
export const useToast = () => {
  return {
    success: successToast,
    error: errorToast,
    utils: {
      formCheckIsNotCorrect: () => {
        errorToast(
          "Please fill in the empty mandatory fields, and make sure they are filled correctly."
        );
      },
    },
  };
};

export default useToast;
