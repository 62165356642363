
import useToast from "@/composables/useToast";
import useWiseFetch from "@/composables/useWiseFetch";
import { BaseResponse } from "@/services/types";
import {
  getFeatureCatPassingGrade,
  getFeatureCatQuestion,
} from "@/services/v1/feature/cat";
import {
  getMemberCatPassingGrade,
  getMemberCatQuestion,
} from "@/services/v1/member/cat";
import {
  DataCat,
  MemberCatQuestion,
  ResponseGetPassingGrade,
} from "@/services/v1/member/cat/types";
import { gpf } from "@/utils/global-functions";
import { isMember } from "@/utils/roles";
import { PropType, defineComponent, ref, computed, watch } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    cpnsCode: {
      type: String,
      default: "",
    },
    jenisCat: {
      type: String,
      default: "",
    },
    bidangId: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const router = useRouter();
    const toast = useToast();
    const cpnsCode = computed(() => props.cpnsCode);
    const jenisCat = computed(() => props.jenisCat);
    const bidangId = computed(() => props.bidangId);

    // START === passing grade section =======================================================================
    const {
      data: dataPassingGrade,
      pending: pendingPassingGrade,
      error: errorPassingGrade,
      execute: executePassingGrade,
    } = useWiseFetch<BaseResponse<ResponseGetPassingGrade>>(() => {
      if (isMember.value) {
        return getMemberCatPassingGrade(cpnsCode.value);
      } else {
        return getFeatureCatPassingGrade(cpnsCode.value);
      }
    });

    watch(errorPassingGrade, (newVal) => {
      if (newVal) {
        gpf.hideModal("#modal-cat-cpns");
      }
    });

    const responsePassingGrade = computed(
      () => dataPassingGrade.value?.response
    );

    const chosenJurusan = ref("");
    const listJurusan = computed(() => {
      const jurusan = responsePassingGrade.value?.jurusan || [];
      return jurusan.map((item) => ({
        text: item.title,
        value: item.kode,
      }));
    });

    watch(listJurusan, (newVal) => {
      if (newVal.length) {
        chosenJurusan.value = newVal[0].value;
      }
    });

    watch(
      () => props.cpnsCode,
      (newVal) => {
        console.log("newVal cpnsCode ", newVal);
        if (newVal) executePassingGrade();
      },
      {
        immediate: true,
      }
    );
    // END === passing grade section =========================================================================

    // START === handle cat question section =================================================================
    const {
      data: dataCatQuestion,
      pending: pendingCatQuestion,
      error: errorCatQuestion,
      execute: executeCatQuestion,
    } = useWiseFetch<BaseResponse<DataCat>>(() => {
      let params = { jurusan: bidangId.value };
      if (chosenJurusan.value) {
        params = { jurusan: Number(chosenJurusan.value) };
      }

      if (isMember.value) {
        return getMemberCatQuestion(jenisCat.value, cpnsCode.value, { params });
      } else {
        return getFeatureCatQuestion(cpnsCode.value, { params });
      }
    });

    watch(dataCatQuestion, (newVal) => {
      if (newVal) {
        console.log("newVal dataCatQuestion ", newVal);
        const resData = newVal?.response;
        const catCode = resData?.kode;
        setupModuleDataCatToLocalStorage(resData);
        goToCatTryout(catCode);
      }
    });

    const setupModuleDataCatToLocalStorage = (dataCat: DataCat) => {
      const catCode = dataCat?.kode;
      const allCatQuestion = dataCat?.soal;

      // save cat question data
      window.localStorage.setItem(
        `catQuestionData${catCode}`,
        JSON.stringify(dataCat)
      );

      // setup payload for submit cat
      const result = allCatQuestion.map((catQuestion: MemberCatQuestion) => {
        const obj = {
          nomor: catQuestion.nomor,
          idSoal: catQuestion.id,
          opsi: catQuestion.answer,
          ragu: catQuestion.ragu ? "Y" : "N",
        };
        return obj;
      });
      window.localStorage.setItem(
        `answerCatQuestion${catCode}`,
        JSON.stringify({ result })
      );
    };

    watch(errorCatQuestion, (newVal) => {
      if (newVal) {
        const messageErr = newVal?.data?.diagnostic?.message;
        toast.error(messageErr);
      }
    });

    const onBeginCatClick = async () => {
      gpf.gLoading.show();
      await executeCatQuestion();
      gpf.gLoading.hide();
    };

    const goToCatTryout = (catCode: string) => {
      setTimeout(() => {
        gpf.hideModal("#modal-cat-cpns");
        const path = `cat-cpns/${cpnsCode.value}`;
        router.push({
          path,
          query: {
            catCode: catCode,
          },
        });
      }, 700);
    };
    // END === handle cat question section ===================================================================

    return {
      gpf,
      responsePassingGrade,
      chosenJurusan,
      listJurusan,
      onBeginCatClick,
    };
  },
});
